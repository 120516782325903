@import '../../../../variables.css';

.setup-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  border: var(--input-border);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
}

.setup-header {
  font-size: var(--headline-font-size);
  color: var(--headline-text-color);
  margin-bottom: 20px;
  font-weight: var(--headline-font-weight);
}

.setup-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.setup-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}

.setup-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.setup-item label {
  margin-right: 10px;
  font-size: var(--label-font-size);
  color: var(--label-text-color);
}

.setup-item input {
  border: var(--input-border);
  border-radius: var(--border-radius);
  padding: 5px 10px;
  width: 60px;
  background-color: var(--input-field-color);
  color: var(--primary-text-color);
  font-size: var(--input-font-size);
}
