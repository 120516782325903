.timeline-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-top:88px;
  margin-bottom:35px;
}

.timeline-editor > .expandable-child {
  flex-grow: 1;
}
