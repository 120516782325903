@import '../../../../variables.css';

.prompts-compendium-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  border: var(--input-border);
  max-width: 600px;
  min-height: 330px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.prompts-compendium-header {
  font-size: var(--headline-font-size);
  color: var(--headline-text-color);
  margin-bottom: 20px;
  font-weight: var(--headline-font-weight);
}

.prompts-compendium-list {
  min-height: 189px;
  max-height: 189px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 5px;
  margin-top: 8px;
}

.prompts-compendium-list::-webkit-scrollbar, .header-item.scrollbar {
  min-width: 10px;
  max-width: 10px;
}

.prompts-compendium-list::-webkit-scrollbar-track {
  background: var(--menu-bar-color);
  border-radius: 10px;
}

.prompts-compendium-list::-webkit-scrollbar-thumb {
  background: var(--input-field-color);
  border-radius: 10px;
}

.prompts-compendium-header-row, 
.prompt-compendium-item {
  display: flex;
  margin-top:4px;
}

.header-item,
.prompt-item {
  font-size: var(--input-font-size);
  color: var(--label-text-color);
  text-align: left;
}

.header-item {
  font-weight: var(--headline-font-weight);
}

.prompt-item {
  padding-top: 5px;
}

.prompts-compendium-index {
  margin-right: 8px;
}

.prompt-compendium-input {
  margin-left: 50px;
}

.header-item.index {
  flex-basis: calc((100% / 9) * 1);
}

.header-item.name {
  flex-basis: calc((100% / 9) * 4);
}

.add-prompt-button-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.prompt-compendium-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 30px;
}

.prompt-compendium-index {
  margin-right: 10px;
  font-size: var(--text-font-size);
  color: var(--primary-text-color);
}

.prompt-compendium-input {
  width: 100%;
  padding: 6px 10px;
  border: var(--input-border);
  border-radius: var(--border-radius);
  background-color: var(--input-field-color);
  color: var(--primary-text-color);
  font-size: var(--input-font-size);
}


.prompt-delete-button {
  background-color: var(--delete-button-color);
  /* padding-top:5px; */
  min-height: 30px;
  max-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--secondary-text-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
}

.prompt-delete-button:hover {
  background-color: var(--delete-button-hover-color);
}


.no-prompts-message {
  font-size: var(--text-font-size);
  color: var(--primary-text-color);
  text-align: center;
}

.add-delete-prompt-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-prompt-button,
.delete-last-prompt-button {
  padding: var(--button-padding);
  color: var(--secondary-text-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--button-font-size);
}

.add-prompt-button {
  background-color: var(--add-button-color);
}

.add-prompt-button:hover {
  background-color: var(--add-button-hover-color);
}

.delete-last-prompt-button {
  background-color: var(--delete-button-color);
}

.delete-last-prompt-button:hover {
  background-color: var(--delete-button-hover-color);
}
