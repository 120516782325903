.compendia-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.compendia-view.expandable-child {
  flex-grow: 1;
}
